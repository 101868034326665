import {
  Align,
  LayoutModule,
  Orientation,
  Render,
  scrollTo,
  useAnimationStates,
  useRenderedChildren,
  useShowInstructions,
} from '@backstage-components/base';
import {css, cx} from '@emotion/css';
import {motion} from 'framer-motion';
import {useSubscription} from 'observable-hooks';
import {
  CSSProperties,
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  ComponentDefinition,
  JustifyPreset,
  Positioning,
  SchemaType,
  reactName,
} from './StackLayoutDefinition';

// we extend the schema type because rjsf will not automatically pick up dependency types
export interface StackLayoutProps extends SchemaType {
  positioning?: Positioning;
}

export type StackLayoutDefinition = LayoutModule<
  typeof reactName,
  StackLayoutProps
>;

export const StackLayout: FC<StackLayoutDefinition> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />, props} = definition;
  const {autoLayout} = props;
  const renderFn: Render = useCallback(
    (Component, details, members) => {
      const itemsLength = 100 / members.length;
      const flexShorthand =
        autoLayout === true ? `flex: 1 1 ${itemsLength}%;` : '';
      const componentStyle = `${
        details.style ? `${details.style}; ` : ''
      }${flexShorthand}`;
      return (
        <Component
          key={`${details.path.join(':')}:${details.mid}`}
          {...details}
          style={componentStyle}
        />
      );
    },
    [autoLayout]
  );
  const renderedChildren = useRenderedChildren(
    Component,
    definition.slots,
    renderFn
  );

  return <StackLayoutBase {...definition}>{renderedChildren}</StackLayoutBase>;
};

export const StackLayoutBase: FC<PropsWithChildren<StackLayoutDefinition>> = (
  definition
) => {
  const {props, children} = definition;

  const {animationStates, layout} = props;
  const {background: bg} = props;

  const {justifyPreset, align, orientation} = layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout(justifyPreset, align, orientation);

  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  const motionOptions = useAnimationStates(
    observable,
    broadcast,
    animationStates
  );

  useSubscription(observable, (inst) => {
    if (inst.type === 'Stacked:scrollTo') {
      const {elementId, anchorElId, scrollX, scrollY} = inst.meta;
      scrollTo({elementId, anchorElId, scrollX, scrollY});
    }
  });

  /**
   * Here we make some decisions about how to style the background of the module based on the props passed in.
   * if certain background styles are not initially added but a background image is specified, we add some default styles.
   */
  // If a background image is specified but no background size is specified, default to cover
  const backgroundSizeStyle = bg?.backgroundSize
    ? bg?.backgroundSize
    : bg?.backgroundImage
    ? 'cover'
    : undefined;

  // If a background image is specified but no background repeat is specified, default to no-repeat
  const backgroundRepeatStyle = bg?.backgroundRepeat
    ? bg?.backgroundRepeat
    : bg?.backgroundImage
    ? 'no-repeat'
    : undefined;

  // If a background image is specified but no background position is specified, default to center
  const backgroundPositionStyle = bg?.backgroundPosition
    ? bg?.backgroundPosition
    : bg?.backgroundImage
    ? 'center'
    : undefined;

  const backgroundClassName = css`
    ${bg?.backgroundImage && `background-image: url(${bg?.backgroundImage})`};
    ${bg?.backgroundAttachment &&
    `background-attachment: ${bg?.backgroundAttachment}`};
    ${bg?.backgroundColor && `background-color: ${bg?.backgroundColor}`};
    ${backgroundSizeStyle && `background-size: ${backgroundSizeStyle}`};
    ${backgroundPositionStyle &&
    `background-position: ${backgroundPositionStyle}`};
    ${backgroundRepeatStyle && `background-repeat: ${backgroundRepeatStyle}`};
  `;

  const spacingClassName = css`
    ${props.spacing?.margin && `margin: ${props.spacing?.margin}`};
    ${props.spacing?.padding && `padding: ${props.spacing?.padding}`};
    ${props.layout?.gap && `gap: ${props.layout?.gap}`};
  `;

  const sizingClassName = css`
    ${props.sizing?.height && `height: ${props.sizing?.height}`};
    ${props.sizing?.minHeight && `min-height: ${props.sizing?.minHeight}`};
    ${props.sizing?.maxHeight && `max-height: ${props.sizing?.maxHeight}`};
    ${props.sizing?.width && `width: ${props.sizing?.width}`};
    ${props.sizing?.minWidth && `min-width: ${props.sizing?.minWidth}`};
    ${props.sizing?.maxWidth && `max-width: ${props.sizing?.maxWidth}`};
  `;

  const styleClassName = css`
    ${definition.style}
    ${props.styleAttr}
  `;

  const positionClassName = css`
    ${props.positioning?.position &&
    `position: ${props.positioning?.position}`};
    ${props?.positioning?.top && `top: ${props.positioning?.top}`};
    ${props?.positioning?.right && `right: ${props.positioning?.right}`};
    ${props?.positioning?.bottom && `bottom: ${props.positioning?.bottom}`};
    ${props?.positioning?.left && `left: ${props.positioning?.left}`};
  `;

  return (
    <motion.div
      id={definition.id}
      data-testid={reactName}
      className={cx(
        css({...layoutStyle}),
        backgroundClassName,
        spacingClassName,
        sizingClassName,
        styleClassName,
        positionClassName,
        definition.mid
      )}
      {...motionOptions}
    >
      {children}
    </motion.div>
  );
};

/**
 * @param justifyPreset settings that will return a corresponding flex layout.
 * @param align sets the `align-items` property.
 * @param orientation used to calculate flex-direction.
 * @returns a flex layout setting based on one of either `preset`, `flexbox` or `orientation`, or else returns `undefined`.
 */
export const computeLayout = (
  justifyPreset: JustifyPreset | undefined,
  align: Align | undefined,
  orientation: Orientation
): CSSProperties | undefined => {
  const direction = orientation === 'horizontal' ? 'row' : 'column';
  if (justifyPreset) {
    if (justifyPreset === 'start') {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'flex-start',
        alignItems: align,
      };
    } else if (justifyPreset === 'end') {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'flex-end',
        alignItems: align,
      };
    } else {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: justifyPreset,
        alignItems: align,
      };
    }
  }
  if (orientation === 'vertical') {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else if (orientation === 'horizontal') {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else {
    return undefined;
  }
};
